.holding {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    &__logo {
        width: 280px;
        @include breakpoint(medium) {
            width: 400px;
        }
    }

    &__container {
        width: 100%;
    }

    &__h1 {
        font-weight: 300;
        margin: 2rem;
    }

    &__hr {
        width: 10rem;
    }

    &__p {
        margin: 2rem;
        font-size: 0.9rem;
        @include breakpoint(medium) {
            font-size: 1.15rem;
        }
    }

    &__a {
        display: inline-block;
        font-weight: 700;
        color: $light-green;
    }
}